import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, EMPTY, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { InteractionMonthlyCycleType, InteractionType, ReportByFilter } from '@app/models';
import { ImportJobStatus, ImportJobStatusEnum } from '@app/models';
import { Segment } from '@app/models';
import { Operation } from '@app/models';
import { Customer } from '@app/models';
import { InteractionStatus } from '@app/models';
import { User } from '@app/models';
import { MonthlyCycle } from '@app/models';
import { MonitorInteractionItemService, OrganizationService, UserService } from '@app/services';
import { SegmentService } from '@app/services';
import { InteractionService } from '@app/services';
import { MonthlyCycleService } from '@app/services';
import { OperationService } from '@app/services';
import { OperationServiceV2 } from 'app/_services/v2/operation.service';
import {AgentFilterComponent} from "./agent/agent-filter.component";
import { DistinctChecklistItem } from 'app/_models/distinct-checklist-items';
import { InteractionMetadataService } from '../../_services/interaction-metadata.service';
import { environment } from '../../../environments/environment';
import { AgentService } from 'app/_services/agent.service';

@Component({
	selector: 'evo-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
	@Input() filters: FormGroup;
	@Input() isDeadlineDisabled = false;
	@Input() showInteractionType = false;
	@Input() isCicloFechamentoDisabled = true;
	@Input() forceSize: FilterSizes[];
	@Input() userFilterBySegmentOperation: boolean = false;
	@Input() public limitDate: boolean = false;
	@Input() showItemsFilter: boolean = false;
	@Input() public metadataColumns: number = 5;

	segments: Segment[] = [];
	operations: Operation[] = [];
	customers: Customer[] = [];
	interactionStatusList: InteractionStatus[] = [];
	interationTypes: InteractionType[] = [];
	importJobStatus: ImportJobStatus[];
	parsedMetadata: { [key: string]: any[] } = {};
	private fullMetadata: { [key: string]: any[] } = {};

	@Input()
	checklistItems: DistinctChecklistItem[] = [];

	currentUser: User;
	selectMultipleMaxLabels = 2;

	shouldLimitDate = false;

	monthlyCycles: MonthlyCycle[] = [];

	sub = new Subscription();

	forcedMonitorDate: Date[];

	public searchMetadata$ = new BehaviorSubject('');
	public searchMetadataKey$ = new BehaviorSubject('');
	public currOffset = 0;
	public metadataLastPage: any[] = [];
	public canSearchMetadata = new BehaviorSubject(false);

	public isMetadataLoading = false;
	private lastRequest: any = null;
	private destroy$ = new Subject<void>();

	@ViewChild('agentFilterComponent') agentFilterComponent: AgentFilterComponent;

	public get cycleAsContactDate(): boolean {
		return this.organizationService.currentOrganization.plan.interactionConfiguration.monthlyCycleType === InteractionMonthlyCycleType.CONTACTED_AT;
	}

	public get formChecklistItems(): FormControl {
		return this.filters.get('checklistItems') as FormControl;
	}

	constructor(private userService: UserService,
	            private segmentService: SegmentService,
	            private interactionService: InteractionService,
	            private monthlyCycleService: MonthlyCycleService,
	            private changeDetection: ChangeDetectorRef,
	            private operationService: OperationService,
	            private translate: TranslateService,
				private operationServiceV2: OperationServiceV2,
				private monitorInteractionItemService: MonitorInteractionItemService,
				private interactionMetadataService: InteractionMetadataService,
				private organizationService: OrganizationService,
	            private fb: FormBuilder,
				private agentService: AgentService) {
	}

	ngOnInit() {
		this.currentUser = this.userService.currentUser;

		if (this.hasFilter('importJobStatus')) {
			this.completeImportJobStatus();
		}

		this.sub.add(this.filters.get('segments').valueChanges.subscribe(segments => {
			if (segments.length == 0) {
				this.operations = [];
				this.operationsFilter.setValue([]);
				this.canSearchMetadata.next(false);
				return;
			}

			this.operationsFilter.setValue([], {emitEvent: false, onlySelf: true});
			this.updateUserList(segments);
			// this.filterChecklistItems(segments);
		}));

		this.sub.add(this.filters.get('operations').valueChanges.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			tap(() => this.destroy$.next()),
			switchMap(operations => {
				this.isMetadataLoading = true;
				return this.getMetadataFilters({segmentId: this.filters.get('segments').value, operationId: operations}).pipe(
					takeUntil(this.destroy$),
					tap(response => this.completeMetadataFilters(response)),
					finalize(() => this.isMetadataLoading = false)
				)
			})
		).subscribe(operations => {
			this.updateUserList(this.filters.get('segments').value, this.filters.get('operations')!.value);

		}));

		this.sub.add(this.canSearchMetadata.subscribe(item => {
			if (!item) {
				this.filters.get('metadata').reset();

				for (const key of Object.keys(this.parsedMetadata)) {
					const control = this.filters.get(`metadata.${key}`);
					if (control) {
						control.disable();
					}
				}

			}
		}))

		if (this.hasFilter('segments') && this.hasFilter('operations')) {
			this.filterSegments().add(() => this.filterOperations());

			if (this.hasFilter('cicloFechamento')) {
				this.sub.add(this.operationsFilter.valueChanges.subscribe(operations => {
					this.cicloFechamento[operations && operations.length > 0 ? 'enable' : 'disable']();
				}));
			}
		}

		this.interactionService.getAllStatus().subscribe(response => {
			this.interactionStatusList = response.map(status => new InteractionStatus().deserialize(status));
		});

		if (this.hasFilter('monitorDate')) {
			this.sub.add(this.filters.get('monitorDate').valueChanges.subscribe(date => {
				this.resetCycleWhenMonitorDateIsDifferent(date);
			}));
		}

		this.translate.get('interactionsType.MANUAL').subscribe(
			translation => {
				this.interationTypes = [...this.interationTypes, {
					name: translation,
					value: 'MANUAL'
				} as InteractionType];
			});

		this.translate.get('interactionsType.AUTOMATIC').subscribe(
			translation => {
				this.interationTypes = [...this.interationTypes, {
					name: translation,
					value: 'AUTOMATIC'
				} as InteractionType];
			});

		this.searchMetadata$.asObservable().pipe(
			filter((searchTerm: string) => searchTerm && searchTerm.length > 2),
			debounceTime(350),
			tap(() => this.currOffset = 0),
			switchMap((searchTerm: string) => {
				let filter: any = {
					segmentId: this.filters.get('segments').value,
					operationId: this.filters.get('operations').value,
					linesPerPage: 30,
					page: 0,
					metadataKey: this.searchMetadataKey$.value,
					metadataValue: this.searchMetadata$.value
				}

				return this.interactionMetadataService.getInteractionMetadataValues(filter);
			})
		).subscribe((metadata) => {
			this.fullMetadata[this.searchMetadataKey$.value] = metadata.content;
			this.currOffset = this.currOffset + 1;
			this.metadataLastPage[this.searchMetadataKey$.value] = metadata.last;
		});

		// TAGS
		this.getMetadataFilters().subscribe(data => this.completeMetadataFilters(data));;
	}

	private getMetadataFilters(filter?: { segmentId: number[], operationId: number[] }) {
		this.lastRequest = this.interactionMetadataService.getInteractionMetadataInitialValues(filter);
		return this.lastRequest;
	}

	private completeMetadataFilters(data: any): void {
		if(this.filters.get('segments').value && this.filters.get('segments').value.length > 0) {
			this.canSearchMetadata.next(true);
		}

		if (this.parsedMetadata && Object.keys(this.parsedMetadata).length == 0) {
			this.parsedMetadata = data;

			const metadataGroup = this.fb.group({});

			for (const key of Object.keys(this.parsedMetadata)) {
				metadataGroup.addControl(key, this.fb.control({
					value: null,
					disabled: !this.canSearchMetadata.value
				}));
			}

			this.filters.setControl('metadata', metadataGroup);
			this.changeDetection.detectChanges();
			return;
		}

		for (const key of Object.keys(this.parsedMetadata)) {
			this.parsedMetadata[key] = data[key];
			this.metadataLastPage[key] = true;
			// Check if the control exists before attempting to update it
			const control = this.filters.get(`metadata.${key}`);

			if (control) {
				// Set each control to enabled; replace false with true to disable
				control.enable(); // or control.disable() to disable
			}
		}
	}

	public nextMetadataBatch(): void {
		this.interactionMetadataService.getInteractionMetadataValues({
			segmentId: this.filters.get('segments').value,
			operationId: this.filters.get('operations').value,
			linesPerPage: 30,
			page: this.currOffset,
			metadataKey: this.searchMetadataKey$.value,
			metadataValue: this.searchMetadata$.value
		}).subscribe(metadata => {
			this.fullMetadata[this.searchMetadataKey$.value] = metadata.content;
			this.currOffset = this.currOffset + 1;
			this.metadataLastPage[this.searchMetadataKey$.value] = metadata.last;
		});
	}

	public searchMetadata(innerTag: string, term: string): void {
		if (this.canSearchMetadata) {
			this.searchMetadataKey$.next(innerTag);
			this.searchMetadata$.next(term.length > 0 ? term : null);
		}
	}

	private updateUserList(segments: Segment[] = [], operations: Operation[] = []): void {
		this.agentService.filter({
			name: '',
			number: '',
			linesPerPage: 10,
			segmentIds: segments || [],
			operationIds: operations || [],
			page: 0,
			orderBy: 'name',
			direction: 'ASC'
		}).subscribe(data => {
			this.agentFilterComponent.users = data.content;
		});
	}

	get cicloFechamento() {
		return this.filters.get('cicloFechamento');
	}

	get operationsFilter() {
		return this.filters.get('operations');
	}

	get checklistItem() {
		return this.filters.get('checklistItems');
	}

	onMonthlyCycleChanged() {
		const cycle = this.monthlyCycles.find(cycle => cycle.id === this.cicloFechamento.value);
		if (cycle === undefined) return null;

		const startAt = cycle.startAt.slice(0, 10).split('-');
		const finishAt = cycle.finishAt.slice(0, 10).split('-');
		this.forcedMonitorDate = [
			new Date(+startAt[0], +startAt[1] - 1, +startAt[2]),
			new Date(+finishAt[0], +finishAt[1] - 1, +finishAt[2])
		];
		this.changeDetection.detectChanges();
	}

	filterSegments() {
		return this.segmentService.filter({}).subscribe(response => {
			this.segments = response.content.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
		});
	}

	filterOperations() {
		const segments = this.filters.value.segments;
		segments && segments.length > 0 ? this.operationServiceV2.getOperations(this.filters.value.segments).subscribe(response => {
			this.operations = response.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
			this.operationsFilter.setValue(this.operations.map(o => o.id));
		}).add(() => {
			this.operations.length > 0 ? this.filterCycles().subscribe(cycles => {
				this.monthlyCycles = cycles as any;
				if (this.operations.length === 0) {
					if (this.hasFilter('cicloFechamento')) {
						this.cicloFechamento.patchValue(null);
					}

					this.forcedMonitorDate = null;
				}
			}) : of([]);
		}) : [];
	}

	filterCycles() {
		if (!this.hasFilter('cicloFechamento')) {
			return EMPTY;
		}

		const operations = this.operationsFilter.value;
		const cycles$ = operations && operations.length > 0 ? this.monthlyCycleService.findCyclesByOperationId(this.filters.value.operations) : of([]);
		return cycles$.pipe(tap(cycles => {
			this.monthlyCycles = cycles as any;
			if (this.cicloFechamento.value) {
				this.cicloFechamento.patchValue(this.cicloFechamento.value);
				this.cicloFechamento.updateValueAndValidity();
			}
		}));
	}

	onSelectSegmentsAll() {
		this.filters.get('segments').setValue(this.segments.map(seg => seg.id));
		this.filterOperations();
	}

	onSelectOperationsAll() {
		this.operationsFilter.setValue(this.operations.map(op => op.id));
	}

	getItemLabelByValue(items, value) {

		const item = items.find(item => item.id == value.id);
		return item ? item.name : '';
	}

	getDistinctItemLabelByValue(distinctChecklistItems, value) {
		const item = distinctChecklistItems.find(item => item.id == value.id);
		let segment = (item.checklist != null && item.checklist.segment != null && item.checklist.segment.name != null) ? '(' + item.checklist.segment.name + ')' : '';
		return item ? item.name + segment : '';
	}

	resetCycleWhenMonitorDateIsDifferent(monitorDate) {
		const cicloFechamento = this.monthlyCycles.find(cycle => cycle.id === this.cicloFechamento.value);
		if (!monitorDate || !cicloFechamento) return null;

		if (!(cicloFechamento.startAt.slice(0, 10) == monitorDate[0].toISOString().slice(0, 10)) && !(cicloFechamento.finishAt.slice(0, 10) == monitorDate[1].toISOString().slice(0, 10))) {
			this.cicloFechamento.patchValue(null);
		}
	}

	public hasFilter(filterName: string): boolean {
		return !!this.filters.get(filterName);
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}

	private completeImportJobStatus(): void {
		this.importJobStatus = [];

		let count = 0;
		let importJobStatus: ImportJobStatus = new ImportJobStatus();

		for (var propertyName in ImportJobStatusEnum) {
			count++;

			if ((count - 1) % 2 == 0) {
				importJobStatus = new ImportJobStatus();
				importJobStatus.id = propertyName;
				continue;
			}

			importJobStatus.name = propertyName;
			this.importJobStatus = [...this.importJobStatus, importJobStatus];
		}
	}

	public getColSize(field: string): string {
		if (!this.forceSize || this.forceSize.length == 0) {
			return 'col-3';
		}

		let item = this.forceSize.find(item => item.label == field);
		return item ? item.size : 'col-3';
	}

	// TAGS

	public filterOptions(tag: string, searchTerm: any) {
		if (searchTerm) {
			const filtered = this.fullMetadata[tag].filter(item =>
				item.toString().toLowerCase().includes(searchTerm.toLowerCase())
			);
			this.parsedMetadata[tag] = filtered.slice(0, 30);
		} else {
			this.parsedMetadata[tag] = this.fullMetadata[tag].slice(0, 30);
		}
	}

	public getTags(): string[] {
		let tags = Object.keys(this.parsedMetadata);

		if (this.showItemsFilter) {
			tags.unshift('checklistItems');
		}

		return tags;
	}

	public emptyCols(n: number): any[] {
		return Array(n).fill(null);
	}
}

export interface FilterSizes {
	label: string;
	size: string;
}

