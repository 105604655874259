<button class="btn btn-outline-danger btn-sm" (click)="openModal()">Recusar Contestação</button>

<ng-template #buttonModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Recusar Contestação</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="false">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
		<form [formGroup]="contestationForm">
			<div class="row">
				<div class="form-group col">
					<label class="required">Justificativa para a contestação improcedente</label>
					<ng-select
						[items]="justifications"
						bindLabel="text"
						bindValue="id"
						placeholder="Selecione uma justificativa"
						formControlName="justification">
					</ng-select>
					<div *ngIf="contestationForm.get('justification').invalid && contestationForm.get('justification').touched">
						<small class="text-danger">Selecione uma justificativa válida.</small>
					</div>
				</div>
			</div>

			<div>Por favor, preencha o motivo da contestação do item {{index + 1}} estar sendo recusada</div>
            <div class="row">
                <div class="form-group col">
	                <label for="description">Motivo <span class="text-muted"> - máx. {{contestation.value.length || 0}}/2000 caracteres</span></label>
                    <textarea id="description" type="text" formControlName="contestation" class="form-control" style="resize: none;" rows="5"
                        [ngClass]="{ 'is-invalid': (contestation.dirty || contestation.touched) && contestation.errors }"></textarea>
                    <div *ngIf="(contestation.dirty || contestation.touched) && contestation.errors"
                        class="invalid-feedback">
                        <div *ngIf="contestation.errors.required">Motivo é obrigatório</div>
                    </div>
                </div>
            </div>
        </form>
	</div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Fechar
        </button>
        <button type="button" [disabled]="isLoading || !contestationForm.valid" class="btn btn-primary"
            (click)="saveContestation('CONTESTATION_DECLINED', 'Contestação recusada com sucesso.'); modal.close()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Confirmar
        </button>
    </div>
</ng-template>
