import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
	ChecklistService,
	CreateChecklistService,
	FirstStepsService,
	HelpDeskService,
	OrganizationService,
	SegmentService,
	ToastService
} from '@app/services';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChecklistItemType, QualityPermissions, Segment } from '@app/models';
import { OnlyPremiumDialogComponent } from '../../../../_components/only-premium-dialog/only-premium-dialog.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
	selector: 'app-checklist-step-one',
	templateUrl: './checklist-step-one.component.html',
	styleUrls: ['./checklist-step-one.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0}),
				animate('200ms 260ms ease-in-out', style({ opacity: 1}))
			])
		])
	]
})
export class ChecklistStepOneComponent implements OnInit, OnDestroy {
	@Output() onChecklistTypeChange: EventEmitter<string> = new EventEmitter();

	form: FormGroup;
	subs = new Subscription();
	segments: Segment[] = [];

	public isChecklistTypeBlocked: boolean = false;

	organizationPlan: String;

	public isInternal: boolean = false;

	public get segment() {
		return this.form.get('segment').value;
	}

	public get isCheckListItemTypeVariable(): boolean {
		return this.createChecklistService.currentChecklist.itemType == ChecklistItemType.VARIABLE;
	}

	constructor(private formBuilder: FormBuilder,
		private checklistService: ChecklistService,
		public createChecklistService: CreateChecklistService,
		private organizationService: OrganizationService,
		private modalService: NgbModal,
		private toastService:ToastService,
        private activeModal: NgbActiveModal,
		private helpDeskService: HelpDeskService,
		public firstStepsService: FirstStepsService,
		private ngxPermissionsService: NgxPermissionsService,
		private segmentService: SegmentService) { }

	ngOnInit(): void {
		if(this.createChecklistService.currentChecklist?.id) {
			this.segments = [ this.createChecklistService.currentChecklist.segment ];
			this.isChecklistTypeBlocked = true;

			this.getChecklist();
		} else {
			this.filterSegments();
		}

		this.form = this.formBuilder.group({
			name: [this.createChecklistService.currentChecklist?.name, [Validators.required, Validators.maxLength(50)]],
			segment: [{
				value: this.createChecklistService.currentChecklist?.segment ? this.createChecklistService.currentChecklist.segment : null,
				disabled: !!this.createChecklistService.currentChecklist?.segment
			}, Validators.required],
			manual: [{
				value: this.createChecklistService.currentChecklist?.id ? this.createChecklistService.currentChecklist.checkListType == 'MANUAL' : this.organizationService.currentOrganization?.plan?.type === 'FREE',
				disabled: !!this.createChecklistService.currentChecklist?.id
			}],
			automatic: [{
				value: this.createChecklistService.currentChecklist?.id ? this.createChecklistService.currentChecklist.checkListType != 'MANUAL' : this.organizationService.currentOrganization?.plan?.type !== 'FREE',
				disabled: !!this.createChecklistService.currentChecklist?.id
			}]
		});

		this.organizationPlan = this.organizationService.currentOrganization?.plan?.type;

		if(this.organizationPlan === 'FREE' || this.createChecklistService.currentChecklist.itemType == ChecklistItemType.VARIABLE) {
			this.automatic.disable({ onlySelf: true, emitEvent: false });
			this.manual.disable({ onlySelf: true, emitEvent: false });
		}

		if(!this.createChecklistService.currentChecklist.segment.speechEnvironmentId)  {
			this.manual.setValue(true);
			this.manual.disable({ onlySelf: true, emitEvent: false });

			this.automatic.setValue(false);
			this.automatic.disable({ onlySelf: true, emitEvent: false });
		}

		this.setManualAndAutomaticToggleFeature();
	}

	public getChecklist(): void {
		if(!this.createChecklistService.currentChecklist.id) {
			return;
		}
	}

	public showHelper(): void {
		this.helpDeskService.helperContent = `
			<h5>O que são os checklists?</h5>

			<p>O checklist é um conjunto de itens que devem ser realizados pelo operador durante uma interação com o cliente, ex: “Confirmação de cadastro”.</p>

			<p>Cada checklist está relacionado a um único segmento e cada segmento tem um único checklist.</p>

			<p>Com os checklists do Quality é possível:
				<ul><li>Checar a implementação de procedimentos padrões (manualmente ouvindo a chamada ou digitalmente com o Speech Analytics)</li>
				<li>Analisar incidências e não conformidades.</li></ul></p>
		`;

		this.helpDeskService.showHelpSidebar = true;
	}

	filterSegments() {
		return this.segmentService.getSegments().subscribe(response => {
			if (response.length === 0 && this.createChecklistService.isFirstSteps) {
				this.firstStepsService.setStepAsEnabled(1);
				this.firstStepsService.setCurrentStep(1);
				this.toastService.showDanger('Você não possui nenhum segmento disponível para criar uma nova checklist.');
				this.activeModal.close();
				return;
			}

			this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
				data => {
					if(data) {
						this.isInternal = true;
						this.segments = response;
					} else {
						this.segments = response.filter((item: any) => item.checklists.length === 0);
					}
				});
		});
	}

	get manual() { return this.form.get('manual'); }
	get automatic() { return this.form.get('automatic'); }
	get isAnyCreationModeSelected() { return this.manual.value || this.automatic.value }

	setManualAndAutomaticToggleFeature() {
		this.subs.add(this.manual.valueChanges.pipe(filter((manual) => !!manual)).subscribe(manual => {
			if(this.organizationPlan !== 'FREE') this.automatic.enable({ onlySelf: true, emitEvent: false });
			this.manual.disable({ onlySelf: true, emitEvent: false });

			this.automatic.setValue(false);
			this.onChecklistTypeChange.emit('MANUAL');
		}));

		this.subs.add(this.automatic.valueChanges.pipe(filter((automatic) => !!automatic)).subscribe(automatic => {
			this.manual.enable({ onlySelf: true, emitEvent: false });
			this.automatic.disable({ onlySelf: true, emitEvent: false });

			this.manual.setValue(false);
			this.onChecklistTypeChange.emit('AUTOMATIC');
		}));
	}

	prevStep() {
		const modal = this.createChecklistService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.modalService.dismissAll();
		}));
	}

	nextStep() {
		const checklist = this.form.value;
		checklist.checkListType = this.automatic.value ? 'AUTOMATIC' : 'MANUAL';

		this.createChecklistService.changeCreationMode$.next(checklist.checkListType);
		delete checklist.automatic;
		delete checklist.manual;

		if(!checklist.segment) {
			checklist.segment = this.form.get('segment').value;
		}

		this.createChecklistService.currentChecklist.name = checklist.name;
		this.createChecklistService.currentChecklist.segment = checklist.segment;
		this.createChecklistService.currentChecklist.checkListType = checklist.checkListType;

		this.createChecklistService.nextStep();
	}

    openPremiumModal() {
		if(this.organizationPlan === 'FREE') {
			this.modalService.open(OnlyPremiumDialogComponent, {size: 'lg'});
		}
    }

	public get canCreateChecklist(): boolean {
		if(this.segment?.checklists.length > 0 && !this.isInternal) {
			return false;
		}

		return true;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
