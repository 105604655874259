import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContestationAi, ContestationAiFilter, PageResponse } from '@app/models';
import { Segment } from '@app/models';
import { User } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import {UsersSegmentsOperationsRequest} from "../_models/users-segments-operations.request";

@Injectable({providedIn: 'root'})
export class ContestationAiService extends BaseServiceClass<ContestationAi> {

	constructor(protected httpService: HttpClient) {
		super(httpService, '/contestation-ai');
	}

	public patch(obj: any, id: number): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/${id}`, obj);
	}

	// ${this.entity_url}
	public getVariables(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/variables`);
	}
}
