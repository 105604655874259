<form [formGroup]="filters" style="margin-bottom: 2em;">
    <div id="advancedFilter" class="advanced-filter">
        <div class="row">
	        <div class="col form-group" *ngIf="hasFilter('importJobStatus')">
		        <label>Status</label>
		        <evo-select>
			        <ng-select  formControlName="importJobStatus"
			                    [multiple]="false"
			                    [items]="importJobStatus"
			                    bindValue="id"
			                    bindLabel="name"
			                    placeholder="Selecione um status">
			        </ng-select>
		        </evo-select>
	        </div>

            <div class="col form-group" *ngIf="hasFilter('segments')">
                <label for="segment">Segmento</label>
                <evo-select iconUnicode="f03a">
                    <ng-select  formControlName="segments"
                                [multiple]="true"
                                (change)="filterOperations()"
                                [items]="segments"
                                bindValue="id"
                                bindLabel="name"
                                (clear)="canSearchMetadata.next(false)"
                                placeholder="Selecione um segmento">
                        <ng-template ng-header-tmp>
                            <div><span class="btn btn-link" (click)="onSelectSegmentsAll()">Selecionar Todos</span></div>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
                            <div class="ng-value d-flex" style="max-width: 32%" container="body"
                                placement="top" [ngbTooltip]="getItemLabelByValue(segments, item)"
                                *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
                                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                <span class="ng-value-label item-label" style="font-size: 0.9em">{{getItemLabelByValue(segments, item)}}</span>
                                </div>
                                <div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
                                    <span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
                                </div>
                        </ng-template>
                    </ng-select>
                </evo-select>
            </div>
            <div class="col form-group" *ngIf="hasFilter('operations')">
                <label for="operation">Operação</label>
                <evo-select iconUnicode="f095">
                    <ng-select  formControlName="operations"
                                [multiple]="true"
                                placeholder="Selecione uma operação"
                                (change)="filterCycles().subscribe()"
                                [items]="operations"
                                (clear)="canSearchMetadata.next(false)"
                                bindValue="id"
                                bindLabel="name">
                        <ng-template ng-header-tmp>
                            <div><span class="btn btn-link" (click)="onSelectOperationsAll()">Selecionar Todos</span></div>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
                            <div class="ng-value d-flex" style="max-width: 32%" container="body"
                                placement="top" [ngbTooltip]="getItemLabelByValue(operations, item)"
                                *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
                                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                <span class="ng-value-label item-label" style="font-size: 0.9em">{{getItemLabelByValue(operations, item)}}</span>
                                </div>
                                <div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
                                    <span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
                                </div>
                        </ng-template>
                    </ng-select>
                </evo-select>
            </div>
            <div class="col" *ngIf="hasFilter('agent')">
                <app-agent-filter #agentFilterComponent formControlName="agent" ></app-agent-filter>
            </div>
			<div class="col form-group" *ngIf="showInteractionType && hasFilter('interactionType')">
				<label>Tipo de Monitoria</label>
				<evo-select>
					<ng-select [items]="interationTypes"
							   formControlName="interactionType"
							   [multiple]="false"
							   bindLabel="name"
							   placeholder="Selecione um tipo"
							   bindValue="value">
					</ng-select>
				</evo-select>
			</div>
            <div class="col form-group" *ngIf="hasFilter('status')">
                <label for="status">Status</label>
                <evo-select iconUnicode="f02b">
                    <ng-select  [items]="interactionStatusList"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                                placeholder="Selecione um status"
                                formControlName="status">
                    </ng-select>
                        <ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
                            <div class="ng-value d-flex" style="max-width: 32%" container="body"
                                placement="top" [ngbTooltip]="getItemLabelByValue(interactionStatusList, item)"
                                *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
                                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                <span class="ng-value-label item-label" style="font-size: 0.9em">{{getItemLabelByValue(interactionStatusList, item)}}</span>
                                </div>
                                <div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
                                    <span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
                                </div>
                        </ng-template>
                </evo-select>
            </div>
		</div>
		<div class="row" style="padding-bottom: 30px;">
            <div class="col form-group" *ngIf="hasFilter('contactDate')">
                <label>Data de Contato</label>
	            <datepicker-range-popup formControlName="contactDate" [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate"
	                                    [forceChange]="forcedMonitorDate" *ngIf="cycleAsContactDate"></datepicker-range-popup>
	            <datepicker-range-popup formControlName="contactDate" [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate"
	                                    *ngIf="!cycleAsContactDate"></datepicker-range-popup>
            </div>
            <div class="col form-group" *ngIf="hasFilter('monitorDate')">
                <label>Data de Monitoria</label>
                <datepicker-range-popup formControlName="monitorDate" (onDateClear)="!!filters.get('cicloFechamento').value && filters.get('cicloFechamento').patchValue(null)"
                                        [forceChange]="forcedMonitorDate" [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate" *ngIf="!cycleAsContactDate">
                </datepicker-range-popup>
	            <datepicker-range-popup formControlName="monitorDate" (onDateClear)="!!filters.get('cicloFechamento').value && filters.get('cicloFechamento').patchValue(null)"
	                                    [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate" *ngIf="cycleAsContactDate">
	            </datepicker-range-popup>
            </div>

	        <!-- Import Job Dates :: START -->
	        <div class="col form-group" *ngIf="hasFilter('importJobStartDate')" [ngClass]="getColSize('importJobStartDate')">
		        <label>Iniciada em</label>
		        <datepicker-range-popup formControlName="importJobStartDate" [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate"></datepicker-range-popup>
	        </div>
	        <div class="col form-group" *ngIf="hasFilter('importJobEndDate')" [ngClass]="getColSize('importJobEndDate')">
		        <label>Finalizada em</label>
		        <datepicker-range-popup formControlName="importJobEndDate" [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate"></datepicker-range-popup>
	        </div>
	        <!-- Import Job Dates :: END -->

            <div class="col form-group" *ngIf="!isDeadlineDisabled && hasFilter('deadLine')">
                <label>Prazo</label>
                <datepicker-range-popup formControlName="deadLine" [shouldLimitDate]="shouldLimitDate" [limitDate]="limitDate"></datepicker-range-popup>
            </div>

			<div class="col form-group" *ngIf="!isCicloFechamentoDisabled">
				<label>Ciclo de Monitoria</label>
				<evo-select>
					<ng-select [items]="monthlyCycles"
							   formControlName="cicloFechamento"
							   (change)="onMonthlyCycleChanged()"
							   (clear)="forcedMonitorDate = null"
							   [multiple]="false"
							   bindLabel="name"
							   placeholder="Selecione um ciclo"
							   bindValue="id">
					</ng-select>
				</evo-select>
			</div>

            <div class="col form-group" *ngIf="hasFilter('grade')">
                <label for="grade">Nota</label>
                <evo-range-slider formControlName="grade" [floor]="0" [ceil]="100"></evo-range-slider>
            </div>
        </div>

	    <ng-container *ngFor="let tag of getTags(); let i = index">
		    <div class="row metadata-filter" formGroupName="metadata" *ngIf="i % metadataColumns === 0">
			    <div class="col form-group" *ngFor="let innerTag of getTags().slice(i, i + metadataColumns)">
				    <ng-container *ngIf="innerTag !== 'checklistItems'">
					    <label>{{ innerTag }} <span *ngIf="canSearchMetadata.value">({{parsedMetadata[innerTag]?.length ?? 0}})</span></label>
					    <evo-select>
						    <ng-select [formControlName]="innerTag"
						               [items]="parsedMetadata[innerTag]"
						               placeholder="Selecione um item"
						               [notFoundText]="searchMetadata$.value ? 'Nenhum metadado encontrado' : 'Pesquise um metadado'"
						               (clear)="searchMetadataKey$.next(innerTag); searchMetadata$.next(null); metadataLastPage[searchMetadataKey$.value] = true;"
						               (search)="searchMetadata(innerTag, $event.term)"
						               [tooltip]="!canSearchMetadata.value ? 'Selecione segmentos e operações para filtrar metadados.' : ''">
							    <ng-template ng-option-tmp ng-label-tmp let-item="item">
								    {{ item }}
							    </ng-template>
							    <!--<ng-template ng-footer-tmp *ngIf="!metadataLastPage[searchMetadataKey$.value]">
								    <button class="btn btn-link" (click)="nextMetadataBatch()">Listar mais itens</button>
							    </ng-template>-->
						    </ng-select>
					    </evo-select>
				    </ng-container>
				    <ng-container *ngIf="innerTag == 'checklistItems'">
					    <label>Itens do Checklist</label>
					    <evo-select iconUnicode="2611">
						    <ng-select [items]="checklistItems"
						               [formControl]="formChecklistItems"
						               [multiple]="true"
						               bindLabel="name"
						               bindValue="id"
						               placeholder="Selecione um item">
							    <ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
								    <div class="ng-value d-flex" style="max-width: 32%" container="body"
								         placement="top" [ngbTooltip]="getDistinctItemLabelByValue(checklistItems, item)"
								         *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
									    <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
									    <span class="ng-value-label item-label" style="font-size: 0.9em">{{getDistinctItemLabelByValue(checklistItems, item)}}</span>
								    </div>
								    <div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
									    <span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
								    </div>
							    </ng-template>
						    </ng-select>
					    </evo-select>
				    </ng-container>
			    </div>
			    <div class="col form-group" *ngFor="let n of emptyCols(metadataColumns - getTags().slice(i, i + metadataColumns).length)"></div>
		    </div>
	    </ng-container>
    </div>
</form>
