<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Nova Contestação IA</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body contestation-ai" style="padding-top: 0px;">
	<div class="" *ngIf="!isFormReady">
		Carregando...
	</div>
	<form [formGroup]="contestationAiForm" (ngSubmit)="save()" *ngIf="isFormReady">
		<div class="row" style="position: relative">
			<div class="col">
				<div class="row">
					<div class="col-12">
						<div class="form-group">
							<label for="name">Nome *</label>
							<input class="form-control" id="name" formControlName="name" type="text"/>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="form-group">
							<label for="description">Descrição</label>
							<textarea class="form-control" id="description" formControlName="description"></textarea>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="form-group">
							<label for="systemPrompt">System Prompt *</label>
							<textarea class="form-control" id="systemPrompt" formControlName="systemPrompt" style="min-height: 150px"></textarea>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="form-group">
							<label for="prompt">Prompt *</label>
							<textarea class="form-control" id="prompt" formControlName="prompt" style="min-height: 300px"></textarea>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<div class="form-group">
							<label for="responseTag">Tag de Resposta * <i class="fas fa-info-circle" ngbTooltip="Para rejeitar a contestação o resultado da tag deve ser  'IMPROCEDENTE' e para aceitar 'PROCEDENTE'"></i></label>
							<input class="form-control" id="responseTag" formControlName="responseTag" type="text" (keydown)="preventInvalidCharacters($event, responseTag.value)"/>
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label for="detailsTag">Tag de Detalhes * <i class="fas fa-info-circle" ngbTooltip="O texto desta tag será exibido em tela como o motivo da decisão da Tag de Resposta."></i></label>
							<input class="form-control" id="detailsTag" formControlName="detailsTag" type="text"  (keydown)="preventInvalidCharacters($event, detailsTag.value)"/>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-6">
						<div class="form-group">
							<label for="aiModel">Modelo de IA *</label>
							<ng-select id="aiModel"
							           formControlName="aiModel"
							           [items]="aiModels"
							           bindValue="value"
							           bindLabel="label"
							           clearable="false"
							           searchable="false">
							</ng-select>
						</div>
					</div>

					<div class="col-6">
						<div class="form-group">
							<label for="user">Usuário da Contestação *</label>
							<ng-select id="user"
							           formControlName="contestationDefaultUserId"
							           [items]="users.content"
							           bindValue="id"
							           bindLabel="name"
							           clearable="false"
							           [notFoundText]="searchUser.value ? 'Nenhum usuário encontrado' : 'Pesquise por um usuário'"
							           (search)="searchUser.next($event.term.length > 0 ? $event.term : null)">
								<ng-template ng-footer-tmp>
									<button class="btn btn-link" [disabled]="users.last" (click)="filterUserNextPage()">Listar mais usuários</button>
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
			</div>
			<div class="variables-container" [class.fixed]="isFixed" #variablesContainer [ngStyle]="{'top': (scrollY - 100) + 'px'}">
				<label style="font-size: 14px; margin-bottom: 8px; line-height: 180%; color: #504E60;">Variáveis</label>

				<div class="variable-list">
					<div class="variable-item text-evollo-primary" *ngFor="let systemVariable of systemVariables" draggable="true" (dragstart)="onDragStart($event, systemVariable.code)" [ngbTooltip]="systemVariable.description" placement="bottom">
						<i class="material-icons text-evollo-primary mr-2">drag_indicator</i>
						{{ systemVariable.name }}
					</div>
					<div class="variable-item" *ngIf="systemVariables.length == 0">
					<span class="text-muted">
						Nenhuma variável encontrada.
					</span>
					</div>
				</div>
			</div>

			<div class="variables-placeholder" [class.show]="isFixed">
			</div>
		</div>

		<!--<div class="d-flex justify-content-end pb-5">
			<button [disabled]="loading || contestationAiForm.invalid" class="btn btn-primary" type="submit">
				<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
				Salvar
			</button>
		</div>-->
	</form>
</div>
<div class="modal-footer" *ngIf="isFormReady">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="loading || contestationAiForm.invalid">
	    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
	    Criar e Selecionar
    </button>
</div>
