import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from "lodash";
import {
	ChecklistItemServiceV2,
	ChecklistService,
	CreateChecklistService,
	ExternalSpeechEnvironmentsService,
	ToastService
} from "@app/services";
import { Checklist, ChecklistItem, ChecklistItemType, SpeechRule, SpeechGenAiQuestionResponse } from "@app/models";
import { BehaviorSubject, Subscription } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
	ChecklistAutomaticItemsTypeDialogComponent
} from "./checklist-automatic-items-type-dialog/checklist-automatic-items-type-dialog.component";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { filter } from "rxjs/operators";

@Component({
	selector: 'app-checklist-automatic-step-three',
	templateUrl: './checklist-automatic-items.component.html',
	styleUrls: ['./checklist-automatic-items.component.scss']
})
export class ChecklistAutomaticItemsComponent implements OnInit, OnDestroy {

	public rulesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public formSubmiting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public rulesGrouped: { group: string, rules: SpeechRule[] }[] = [];
	public  get checklist(): Checklist {
		return this.createChecklistService.currentChecklist;
	};

	public selectedChecklistItems: { name: string, checklistItems: ChecklistItem[], opened: boolean }[] = [];

	public hiddenGroups: string[] = [];
	public isRuleSelectorHidden: boolean = false;

	public checklistItemType = ChecklistItemType;

	public speechAiQuestions: SpeechGenAiQuestionResponse[] = [];

	public formGroup: FormGroup;

	private subs: Subscription = new Subscription();

	public get checklistGroupItems() {
		return this.formGroup.get('checklistGroupItems') as FormArray;
	}

	constructor(private externalSpeechEnvironmentsService: ExternalSpeechEnvironmentsService,
	            private createChecklistService: CreateChecklistService,
	            public checklistService: ChecklistService,
	            public ChecklistItemServiceV2: ChecklistItemServiceV2,
	            private modalService: NgbModal,
	            private activeModal: NgbActiveModal,
	            private toastService: ToastService,
	            private fb: FormBuilder) {
		this.formGroup = this.fb.group({
			checklistGroupItems: this.fb.array([])
		});
	}

	public getGroupItems(group: AbstractControl) {
		return group.get('checklistItems') as FormArray;
	}

	ngOnInit(): void {
		this.getRules();
		this.getQuestions();
	}

	public getQuestions(): void {
		this.externalSpeechEnvironmentsService.getSegmentQuestions(this.checklist.segmentId).subscribe(
			data => {
				this.speechAiQuestions = data;

				if(this.checklist.id) {
					this.prepareEditForm();
				}
			}, error => {
				this.toastService.showDanger('Ocorreu um erro ao buscar as perguntas do segmento.');
			}
		)
	}

	private prepareEditForm(): void {
		this.checklist.itensChecklist.forEach((item: ChecklistItem) => {
			let checklistItem = this.fb.group({
				id: [item.id, []],
				type: [item.type, []],
				speechCategoryId: [item.speechCategoryId, [Validators.required]],
				group: [item.group.name, []],
				name: [item.name, []],
				descricao: [item.descricao, []],
				itemAnswer: [item.itemAnswer ?? null, []],
				defaultAnswer: [item.itemAnswer ?? null, []],
				active: [item.active, []],
				peso: [{value: item.peso, disabled: item.type == ChecklistItemType.VARIABLE}, [Validators.required]],
				ncg: [item.peso == 100, []],
				checklist: [
					{
						id: this.createChecklistService.currentChecklist.id,
						type: this.createChecklistService.currentChecklist.checkListType,
					},
				],
				ruleFragmentCategoryIA: [item.ruleFragmentCategoryIA, []],
				speechGenAiQuestionId: [item.speechGenAiQuestionId, []],
				speechGenAiQuestionOption: [item.speechGenAiQuestionOption, []]
			});

			this.configureChecklistItem(checklistItem, item.group.name, item.type);
		});
	}

	public getRules(): void {
		this.rulesLoading.next(true);
		this.externalSpeechEnvironmentsService.getEnvironmentRules(this.checklist.segment.speechEnvironmentId).subscribe((rules: any[]) => {
			this.rulesGrouped = _(rules).groupBy('group').map((group, groupName) => {
				this.sidebarShowHideGroup(groupName);
				return ({ group: groupName, rules: group })
			}).value();
		}).add(() => this.rulesLoading.next(false));
	}

	public addChecklistItem(rulesGroup: any, index: number): void {
		let rule = rulesGroup.rules[index];

		if(this.isItemAlreadyAdded(rule)) {
			return;
		}

		let ref = this.modalService.open(ChecklistAutomaticItemsTypeDialogComponent, { centered: true });
		ref.componentInstance.rule = rule;
		ref.result.then((data: ChecklistItemType) => {
			if(data) {
				const checklistItem = this.fb.group({
					id: [null, []],
					type: [data, []],
					speechCategoryId: [rule._id, [Validators.required]],
					group: [rule.group, []],
					name: [rule.name, []],
					descricao: [rule.description, []],
					itemAnswer: [rule.itemAnswer ?? null, []],
					defaultAnswer: [rule.itemAnswer ?? null, []],
					active: [true, []],
					peso: [{value: 0, disabled: data == ChecklistItemType.VARIABLE}, [Validators.required]],
					ncg: [false, []],
					checklist: [
						{
							id: this.createChecklistService.currentChecklist.id,
							type: this.createChecklistService.currentChecklist.checkListType,
						},
					],
					ruleFragmentCategoryIA: [rule.ruleFragmentCategoryIA, []],
					speechGenAiQuestionId: [null, []],
					speechGenAiQuestionOption: [null, []]
				});

				this.configureChecklistItem(checklistItem, rule.group, data);
			}
		});
	}

	private configureChecklistItem(checklistItem: FormGroup, group: string, checklistItemType: ChecklistItemType): void {
		if(checklistItemType == ChecklistItemType.VARIABLE) {
			checklistItem.get('speechGenAiQuestionId').setValidators([Validators.required]);
			checklistItem.get('speechGenAiQuestionOption').setValidators([Validators.required]);
		} else {
			checklistItem.get('peso').setValidators([Validators.required]);
		}

		checklistItem.get('peso').valueChanges.subscribe(data => {
			checklistItem.get('ncg').setValue(data >= 100, {emitEvent: false, onlySelf: true});

			if(data > 100) {
				checklistItem.get('peso').setValue(100, { emitEvent: false, onlySelf: true });
			}
		});

		checklistItem.get('ncg').valueChanges.subscribe(data => {
			if(data) {
				checklistItem.get('peso').setValue(100, { emitEvent: false, onlySelf: true });
			} else {
				checklistItem.get('peso').setValue(0, { emitEvent: false, onlySelf: true });
			}
		});

		let existingGroup = this.checklistGroupItems.controls.find(
			(control) => control.get('name')?.value === group
		);

		if (!existingGroup) {
			existingGroup = this.fb.group({
				name: [group, []],
				checklistItems: this.fb.array([]),
			});
			this.checklistGroupItems.push(existingGroup);
		}

		(existingGroup.get('checklistItems') as FormArray).push(checklistItem);
	}

	public removeChecklistItem(checklistItem: AbstractControl, groupIndex: number): void {
		const group = this.checklistGroupItems.at(groupIndex) as FormGroup;
		const items = group.get('checklistItems') as FormArray;

		if (checklistItem.value.id) {
			checklistItem.patchValue({ active: false });
		} else {
			items.removeAt(items.controls.indexOf(checklistItem));
			if (items.length === 0) {
				this.checklistGroupItems.removeAt(groupIndex);
			}
		}
	}

	public isItemAlreadyAdded(rule: SpeechRule): boolean {
		return this.checklistGroupItems.controls.some((group: FormGroup) => {
			return (group.get('checklistItems') as FormArray).controls.some((item: FormGroup) => {
				return item.get('speechCategoryId').value === rule._id;
			});
		});
	}

	// ---- SIDEBAR ----
	public sidebarShowHideGroup(group: string): void {
		if (this.hiddenGroups.includes(group)) {
			this.hiddenGroups = this.hiddenGroups.filter(g => g !== group);
		} else {
			this.hiddenGroups.push(group);
		}
	}

	public sidebarIsGroupHidden(group: string): boolean {
		return this.hiddenGroups.includes(group);
	}

	public nextStep() {
		this.formSubmiting.next(true);

		let checklistAllItens: any[] = this.checklistGroupItems.controls.map(group => {
			return (group.get('checklistItems') as FormArray).getRawValue().map(item => {
				item.group = { name: group.get('name').value };
				return item;
			});
		});

		checklistAllItens = [].concat.apply([], checklistAllItens);

		if(!this.checklist.id) {
			this.checklist.itensChecklist = checklistAllItens;
			this.checklist.segmentId = this.checklist.segment.id;

			this.checklistService.post(this.checklist).subscribe(
				data => {
					this.toastService.showSuccess('Checklist criado com sucesso!');
					this.activeModal.close();
				}, error => {
					if(error.error.error === 'CHECKLIST_ALREADY_EXIST') {
						if(this.checklist.metadata.length == 0) {
							this.toastService.showDanger('Já existe um checklist sem metadados nesse segmento.')
						} else {
							this.toastService.showDanger('Não é possível criar um checklist com os mesmos metadados de outro já existente.');
						}
					}
				}).add(() => this.formSubmiting.next(false));
		} else {
			this.checklist.itensChecklist = [];
			this.checklistService.put(this.checklist, this.checklist.id).subscribe(
				data => {
					this.ChecklistItemServiceV2.updateItemsChecklist(checklistAllItens).subscribe(
						data => {
							this.toastService.showSuccess('Checklist alterado com sucesso!');
							this.activeModal.close();
						}, error => {
							this.toastService.showDanger('Erro ao salvar os itens do checklist.');
							console.error(error);
						}).add(() => this.formSubmiting.next(false));
				}, error => {
					this.toastService.showDanger('Ocorreu um erro ao tentar salvar o Checklist.');
					this.formSubmiting.next(false);
				});
		}
	}

	prevStep() {
		const modal = this.createChecklistService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.createChecklistService.prevStep();
		}));
	}

	public getChecklistItemControl(i, j, field) {
		return ((this.checklistGroupItems?.controls[i] as FormArray)?.controls[j] as FormGroup)?.controls[field] as FormControl;
	}

	ngOnDestroy() {
		this.formSubmiting.unsubscribe();
		this.rulesLoading.unsubscribe();
		this.subs.unsubscribe();
	}

	public get totalPeso(): number {
		let total = 0;
		this.checklistGroupItems.controls.forEach((group: FormGroup) => {
			(group.get('checklistItems') as FormArray).controls.forEach((item: FormGroup) => {
				if(item.get('peso').value != 100) {
					total += item.get('peso').value;
				}
			});
		});
		return this.roundNumber(total, 2);
	}

	public roundNumber(num, scale) {
		if(!("" + num).includes("e")) {
			return +(Math.round(Number(num + "e+" + scale))  + "e-" + scale);
		} else {
			var arr = ("" + num).split("e");
			var sig = ""
			if(+arr[1] + scale > 0) {
				sig = "+";
			}
			return +(Math.round(Number(+arr[0] + "e" + sig + (+arr[1] + scale))) + "e-" + scale);
		}
	}

	public get isValidForm(): boolean {
		if(this.checklist.itemType == ChecklistItemType.VARIABLE) {
			// check if there is at least one checklistItem with type == variable
			let hasVariable = false;
			this.checklistGroupItems.controls.some((group: FormGroup) => {
				(group.get('checklistItems') as FormArray).controls.forEach((item: FormGroup) => {
					if(item.get('type').value == ChecklistItemType.VARIABLE) {
						hasVariable = true;
						return hasVariable;
					}
					return false;
				});
			});

			return this.formGroup.valid && hasVariable;
		}

		return this.totalPeso == 100 && this.formGroup.valid;
	}
}
