import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { ChecklistItemType, SpeechRule } from "@app/models";

@Component({
	selector: 'checklist-automatic-items-type-dialog',
	templateUrl: './checklist-automatic-items-type-dialog.component.html',
	styleUrls: ['./checklist-automatic-items-type-dialog.component.scss']
})
export class ChecklistAutomaticItemsTypeDialogComponent implements OnDestroy {

	@Input() public rule: SpeechRule;
	public typeForm: FormGroup;

	public subs = new Subscription();

	public get typeSelected(): ChecklistItemType {
		if(this.typeForm.get('fixed').value) {
			return ChecklistItemType.FIXED;
		}

		return ChecklistItemType.VARIABLE;
	}

	constructor(public modal: NgbActiveModal,
	            private fb: FormBuilder) {
		this.typeForm = this.fb.group({
			fixed: [true],
			variable: [false]
		});

		this.subs.add(this.typeForm.get('fixed').valueChanges.pipe(filter((manual) => !!manual)).subscribe(manual => {
			this.typeForm.get('variable').setValue(false, {emitEvent: false, onlySelf: true});
		}));

		this.subs.add(this.typeForm.get('variable').valueChanges.pipe(filter((automatic) => !!automatic)).subscribe(automatic => {
			this.typeForm.get('fixed').setValue(false, {emitEvent: false, onlySelf: true});
		}));
	}

	public get isValidForm(): boolean {
		return !(this.typeForm.get('fixed').value === false && this.typeForm.get('variable').value === false);
	}

	public ngOnDestroy() {
		this.subs.unsubscribe();
	}
}
