export interface SpeechRule {
	_id: string;
	name: string;
	description: string;
	group: string;
	active: boolean;
	subType: string;
	itemAnswer: string;
	ruleFragmentCategoryIA: any;

	type: 'fixed' | 'variable';
}
