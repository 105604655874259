import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './baseService.service';
import { environment } from "../../environments/environment";
import { SpeechGenAiQuestionResponse } from "../_models/response/speech-gen-ai-question.response";

@Injectable({
	providedIn: 'root'
})
export class ExternalSpeechEnvironmentsService extends BaseServiceClass<any> {

	constructor(public httpClient: HttpClient) {
		super(httpClient, '/external/speech/environments');
	}

	getEnvironments(onlyNotLinkedEnvironment: boolean): Observable<any[]> {
		let params = {onlyNotLinkedEnvironment};
		return this.httpService.get<any[]>(`${this.entity_url}`, {params});
	}

	getEnvironmentCategories(environmentId: number, subType: string = 'CHECKLIST'): Observable<any[]> {
		let params = {subType};

		return this.httpClient.get<any[]>(`${this.entity_url}/${environmentId}/categories`, {params});
	}

	getEnvironmentRules(environmentId: number, subType: string = 'CHECKLIST', active: boolean = true): Observable<any[]> {
		let params = {subType, active};

		return this.httpClient.get<any[]>(`${this.entity_url}/${environmentId}/rules`, {params});
	}

	public getEnvironmentQuestions(environmentId: any): Observable<any> {
		// /speechGenAiQuestions/environments/{id}
		return this.httpClient.get(`${environment.apiUrl}/speechGenAiQuestions/environments/${environmentId}`);
	}

	public getSegmentQuestions(segmentId: any): Observable<SpeechGenAiQuestionResponse[]> {
		// /speechGenAiQuestions/segments/{id}
		return this.httpClient.get<SpeechGenAiQuestionResponse[]>(`${environment.apiUrl}/speechGenAiQuestions/segments/${segmentId}`);
	}
}
