import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Segment } from './segment';
import { ChecklistItem, ChecklistItemType } from './checklist-item';

export class Checklist implements Deserializable {
    id: number;
    name: string;
    customer: Customer;
    speechCategorySkipIntegration: string;
    segment: Segment;
	segmentId: number;
    checkListType?: string;

	speechCategoryEffectiveIntegration: string;
	speechCategoryNonEffectiveIntegration: string;

    itensChecklist: ChecklistItem[] = [];

	metadata: any;

	active: boolean;

	itemType: ChecklistItemType = ChecklistItemType.FIXED;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
