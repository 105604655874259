<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ checklistItem.name }} - Conceito</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding-top: 0px;">
	<markdown [data]="variableContent"></markdown>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">Fechar</button>
</div>
