<div class="app-component">
  <fixed-top-header *ngIf="currentOrganization && currentUser"
          [currentUser]="currentUser">
  </fixed-top-header>
  <div class="main-app-body">
	  <evo-navigation-bar *ngIf="currentUser && organizationService.currentOrganization && permissionsLoaded">
    </evo-navigation-bar>
    <div class="page-content-wrapper" [ngClass]="{'sidebar-collapse' : getSidebarStatus()}">
      <div class="outlet-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-toasts></app-toasts>

<div class="helper-info" [ngClass]="{'hide' : !helpDeskService.showHelpSidebar }">
	<div class="header">
		<span class="">Ajuda</span>

		<i class="fas fa-times pointer" (click)="helpDeskService.showHelpSidebar = false"></i>
	</div>

	<div class="body">
		<div [innerHTML]="helpDeskService.helperContent"></div>

		<div class="" *ngIf="helpDeskService.showHelpDialog">
			<p>
				<span [innerHTML]="helpDeskService.helpDialogText"></span>

				<a class="text-primary pointer" (click)="openHelpDialog()">
					clicando aqui
				</a>.
			</p>
		</div>
	</div>
</div>

<!--<evo-ai-assistant [username]="currentUser.name"
                  *ngIf="currentUser && aiEnabled"
                  logoPath="/assets/images/logo-evollo.png"
                  [apiUrl]="genAiUrl"
				  [authToken]="currentToken">
</evo-ai-assistant>-->
