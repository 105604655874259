export interface ContestationAi {
	id?: number;
	name: string;
	description?: string;
	systemPrompt: string;
	prompt: string;
	aiModel: string;
	aiModelName: string;
	responseTag: string;
	detailsTag: string;
	contestationDefaultUserId?: any;
	contestationDefaultUser?: any;
	createdAt?: any;
	createdBy?: any;
	updatedAt?: any;
	updatedBy?: any;
}
