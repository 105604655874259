import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';
import { ChecklistItem } from "@app/models";

@Component({
	selector: 'checklist-item-concept-dialog',
	templateUrl: './checklist-item-concept-dialog.component.html',
	styleUrls: ['./checklist-item-concept-dialog.component.scss']
})
export class ChecklistItemConceptDialogComponent {
	public checklistItem: ChecklistItem;
	public variableContent: string;

	constructor(public modal: NgbActiveModal) {
	}
}
