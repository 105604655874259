import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { BaseServiceClass } from './baseService.service';
import {CacheInterceptorService} from "./cache-interceptor.service";
import { RefreshAccessTokenRequest, RefreshAccessTokenResponse } from "@app/models";

@Injectable({
	providedIn: 'root'
})
export class UserService extends BaseServiceClass<any> {

	public jwtToken: string | null = null;
	public refreshToken: string;
	public deviceKey: string;
	public webClientId: string;

	public currentUser$ = new Subject<any>();
	public _currentUser: any;
	get currentUser() {
		return this._currentUser;
	}
	set currentUser(user: any) {
		this.currentUser$.next(user);
		this._currentUser = user;
	}

	public returnUrl: string = '/users';

	private account_url: string | null = null;

	constructor(private http: HttpClient,
				@Inject(DOCUMENT) private document: Document,
				private cacheInterceptorService: CacheInterceptorService) {
		super(http, '/users');
		this.account_url = environment.accountUrl + '/api/v1/users';
	}

	public logout(): void {
		let clonedToken = this.jwtToken;
		this.cacheInterceptorService.clearCache();
		localStorage.clear();
		this.currentUser = undefined;
		this.jwtToken = null;
		this.refreshToken = null;
		this.deviceKey = null;
		this.webClientId = null;
		this.document.location.href = environment.apps['id'] + `/logout?token=${clonedToken}`;
	}

	public filterAll(filters: any): Observable<any> {
		return this.http.get(`${this.entity_url}`, { params: filters });
	}

	public getCurrentUser(): Observable<any> {
		return this.http.get(`${this.account_url}/current`);
	}

	public getCurrentUserPermissions(): Observable<any> {
		return this.http.get(`${this.account_url}/current/roles`);
	}

	public getUserByAccountId(accountId): Observable<any> {
		return this.http.get(`${this.entity_url}/account/${accountId}`);
	}

	public getQualityCurrentUser(): Observable<any> {
		return this.http.get(`${this.entity_url}/current`);
	}

	public refreshAccessToken(refreshToken?: string): Observable<RefreshAccessTokenResponse> {
		let body = {
			refreshToken: refreshToken ?? this.refreshToken,
			deviceKey: this.deviceKey,
			webClientId: this.webClientId
		}

		return this.http.post<RefreshAccessTokenResponse>(`${this.account_url}/refreshAccessToken`, body);
	}

	public updateTokens(jwtToken: string, refreshToken: string, deviceKey: string, webClientId: string): void {
		this.jwtToken = jwtToken;
		this.refreshToken = refreshToken;
		this.deviceKey = deviceKey;
		this.webClientId = webClientId;

		localStorage.setItem('jwtToken', this.jwtToken);
		localStorage.setItem('refreshToken', this.refreshToken);
		localStorage.setItem('deviceKey', this.deviceKey);
		localStorage.setItem('webClientId', this.webClientId);
	}
}
