<div @fadeInOut>
    <div class="body">
        <evo-stepper-title title="Detalhes do checklist {{ isCheckListItemTypeVariable ? 'variável' : null }}" subtitle="Adicione informações sobre o checklist {{ isCheckListItemTypeVariable ? 'variável' : null }}"></evo-stepper-title>
	    <div class="d-flex justify-content-center">
	        <button class="btn btn-link" (click)="showHelper()">Saiba mais</button>
	    </div>
        <form [formGroup]="form">
	        <div class="form-group">
		        <label>Nome*</label>
		        <input class="form-control" formControlName="name" maxlength="50"/>
	        </div>

            <div class="form-group">
                <label class="required">Segmento</label>
                <ng-select formControlName="segment" bindLabel="name" [items]="segments" [placeholder]="segments.length == 0 ? 'Nenhum segmento disponível' : 'Selecione um segmento'"></ng-select>
            </div>

            <div class="form-group">
                <label class="required">Tipo de checklist</label>
                <div class="row">
                    <div class="col-6">
                        <app-checkable-card title="Checklist manual" [isBlocked]="isChecklistTypeBlocked || isCheckListItemTypeVariable"
                                            formControlName="manual"
                                            iconClass="fa-headphones"
                                            description="Realizar a monitoria manualmente, ouvindo o áudio da chamada.">
                        </app-checkable-card>
                    </div>
                    <div class="col-6">
                        <app-checkable-card title="Checklist automático" [isBlocked]="isChecklistTypeBlocked  || isCheckListItemTypeVariable"
                                            formControlName="automatic" [isPremium]=true
                                            iconClass="fa-robot" (click)="openPremiumModal()"
                                            description="Realizar monitoria automaticamente através do recurso de Speech Analytics."
                                            [ngClass]="{'pointer-cursor' : organizationPlan === 'FREE'}">
                        </app-checkable-card>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <evo-stepper-footer [isFormInvalid]="form.invalid || !isAnyCreationModeSelected" (onPrev)="prevStep()" (onNext)="nextStep()"></evo-stepper-footer>
</div>
