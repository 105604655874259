<div>
    <div class="body">
	    <!--<p style="font-size: 14px; font-weight: 600; color: #5E5E5E;">Checklist: {{checklist.name}}</p>-->
	    <!--<p style="font-size: 14px; font-weight: 600; color: #5E5E5E;">Tipo do Checklist: {{checklist.itemType == checklistItemType.FIXED ? 'Fixo' : 'Variável'}}</p>-->
        <p style="font-size: 14px; font-weight: 600; color: #5E5E5E;">Segmento: {{checklist.segment.name}}</p>

	    <div class="text-center" *ngIf="checklistGroupItems.length == 0">
		    <h6 class="text-primary">Selecione novos itens para o seu checklist.</h6>
	    </div>

	    <div class="selected-checklist-items" [formGroup]="formGroup">
		    <div class="" formArrayName="checklistGroupItems">
			    <div class="checklist-item-group" *ngFor="let group of checklistGroupItems.controls; let groupIndex = index" [formGroupName]="groupIndex">
				    <div class="d-flex justify-content-between">
					    <h6>{{ group.get('name')?.value }} ({{ getGroupItems(group).length }})</h6>

					    <!--<button class="btn" (click)="group.opened = !group.opened">
						    <i class="fas fa-chevron-up" *ngIf="group.opened"></i>
						    <i class="fas fa-chevron-down" *ngIf="!group.opened"></i>
					    </button>-->
				    </div>
				    <div class="" formArrayName="checklistItems">
					    <ng-container *ngFor="let checklistItem of getGroupItems(group).controls; let itemIndex = index" [formGroupName]="itemIndex">
						    <div class="checklist-item" [hidden]="!checklistItem.get('active').value">
							    <div class="checklist-item-description">
								    <div class="flex-grow-1">
									    <h6>
										    {{ checklistItem.get('name')?.value }}
										    <span class="badge badge-soft-info badge-checklist" *ngIf="checklistItem.get('type')?.value == checklistItemType.FIXED">Fixo</span>
										    <span class="badge badge-soft-success badge-checklist" *ngIf="checklistItem.get('type')?.value == checklistItemType.VARIABLE">Variável</span>
									    </h6>
									    <p>{{ checklistItem.get('descricao')?.value }}</p>
								    </div>
								    <div class="d-flex align-items-end flex-column">
									    <button class="btn text-danger mb-3" evolloConfirmAction title="Deseja realmente remover este item?" text="Item: {{ checklistItem.get('name')?.value }}" (confirm)="removeChecklistItem(checklistItem, groupIndex)">
										    <i class="fas fa-times"></i>
									    </button>
									    <div class="d-flex mb-2 align-items-center">
										    <input type="checkbox" class="mr-2" formControlName="ncg"> NCG <i class="fas fa-info-circle ml-1" ngbTooltip="Não conformidade grave (NCG)."></i>
									    </div>
									    <div class="d-flex align-items-center">
										    <span>Peso:</span>
										    <input class="form-control ml-2" style="width: 70px" mask="separator.2" separatorLimit="100" formControlName="peso">
									    </div>
								    </div>
							    </div>
							    <div class="checklist-item-footer" *ngIf="checklistItem.get('type')?.value == checklistItemType.VARIABLE">
								    <span>Selecione uma pergunta e uma opção:</span>
								    <div class="flex-grow-1 d-flex mt-1">
									    <ng-select class="flex-grow-1" formControlName="speechGenAiQuestionId"
							               bindValue="id"
							               #question bindLabel="name"
							               [items]="speechAiQuestions"
							               appendTo="body">
									    </ng-select>
									    <div class="mx-2"></div>
									    <ng-select class="flex-grow-1" formControlName="speechGenAiQuestionOption"
							               [items]="question.selectedItems[0]?.value['options'] || []"
							               appendTo="body">
									    </ng-select>
								    </div>
							    </div>
						    </div>
					    </ng-container>
				    </div>
			    </div>
		    </div>
	    </div>

	    <div class="rules-selector" [ngClass]="{'hidden' : isRuleSelectorHidden}">
		    <button class="btn btn-hide-selector" (click)="isRuleSelectorHidden = !isRuleSelectorHidden">
			    <i class="fas fa-list-alt"></i>
		    </button>

		    <div class="rules-selector-header">
			    <h6>Selecione os itens</h6>
			    <p>Selecione os itens de cada grupo para o seu checklist</p>
		    </div>

		    <div class="p-3 text-center" *ngIf="rulesLoading.value">
			    <span>Carregando itens...</span>
		    </div>

		    <div class="rules-groups" *ngIf="!rulesLoading.value">
			    <div class="rule-item-group" *ngFor="let categoryGroup of rulesGrouped" [ngClass]="{'hidden': sidebarIsGroupHidden(categoryGroup.group)}">
				    <div class="rule-group-header">
					    <h6>{{ categoryGroup.group}} ({{ categoryGroup.rules.length}})</h6>
					    <button class="btn text-white" (click)="sidebarShowHideGroup(categoryGroup.group)">
						    <i class="fa fa-chevron-up" *ngIf="!sidebarIsGroupHidden(categoryGroup.group)"></i>
						    <i class="fa fa-chevron-down" *ngIf="sidebarIsGroupHidden(categoryGroup.group)"></i>
					    </button>
				    </div>

				    <div class="rule-item" *ngFor="let rule of categoryGroup.rules; index as i" (click)="addChecklistItem(categoryGroup, i)" [ngClass]="{'disabled': isItemAlreadyAdded(rule)}">
					    <div>
						    <h6>{{ rule.name }}</h6>
						    <p>{{ rule.description }}</p>
					    </div>

					    <button class="btn">
						    <i class="fas fa-chevron-right"></i>
					    </button>
				    </div>

			    </div>
		    </div>
	    </div>
    </div>

    <evo-stepper-footer [isLoading]="formSubmiting.value" [isFormInvalid]="!isValidForm" (onPrev)="prevStep()" (onNext)="nextStep()">
        <div progress class="progress-wrapper">
            <p *ngIf="totalPeso <= 100" class="text-muted text-center">Você possui {{roundNumber(100 - totalPeso, 2) | number: '1.0-2'}} pontos restantes{{ checklist.itemType == checklistItemType.VARIABLE ? ', serão utilizados nos itens variáveis.' : '.'}} <i class="fas fa-info-circle ml-1"></i></p>
            <p *ngIf="totalPeso > 100" class="text-danger">Você excedeu a pontuação em {{roundNumber(100 - totalPeso, 2) | number: '1.0-2'}} pontos <i class="fas fa-info-circle ml-1"></i></p>
            <p class="progress-bar"><ngb-progressbar type="primary" style="background-color: #00c9a7" [value]="totalPeso <= 100 ? totalPeso : 0"></ngb-progressbar></p>
        </div>
    </evo-stepper-footer>
</div>
