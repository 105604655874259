export const environment = {
	COMPANY_NAME: 'evollo',
	production: true,
	APP_NAME: 'QUALITY',
	apiUrl: 'https://ailos-quality.api.evollo.cloud',
	accountUrl: 'https://ailos-account.api.evollo.cloud',
	integrationUrl: 'https://ailos-integration.api.evollo.cloud',
	genAiUrl: 'https://genai-integrator-api.evollo.cloud:8485',
	features: {
		enabledAverageGrade: true,
		enabledChat: false,
		enableQualityDashboard: false,
		enableInteractionSentiment: true,
		enableSupport: false,
		enableFaq: false,
		homepage: '/interactions',
		cycleAsContactDate: false, // false = data de monitoria || true = data de contato
		enableInteractionMonitorCycle: true,
		aiEnabled: true,
		aiInsightsEnabled: true,
		aiInteractionEnabled: true,
		trainingUrl: null,
		genAiOrganizationFilter: false,
		initialInteractionDateFilter: true,
	},
	apps: {
		id: 'https://ailos.id.evollo.com.br',
		admin: 'https://ailos.admin.evollo.com.br',
	},
};
