import { Interaction } from "./interaction";
import { MonitorInteractionItem } from "./monitor-interaction-item";
import { Justification } from "./justification";
import { Contestation } from "./contestation";
import { Flow } from "./flow";
import { GenAiFlag } from "./gen-ai-flag";
import { TimelineItem } from "../_components/timeline-list/timeline-list.component";

export interface InteractionPrint {
	interaction?: Interaction,
	monitorItems?: MonitorInteractionItem[],
	itemsGrouped?: MonitorInteractionItem[][];
	justifications?: Justification[],
	contestations?: Contestation[],
	flow?: Flow,
	genAiFlags?: GenAiFlag[],
	speechInteractionId?: any,
	speechInteractionFlags?: any,
	transcriptionSegments?: any,
	itemsTimeline?: TimelineItem[]
}
