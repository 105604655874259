import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContestationAi, ContestationAiFilter, EvoAIModels, PageResponse, User, UserFilter } from "@app/models";
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { ContestationAiService, ToastService, UserService } from "@app/services";
import { GenAiIntegratorService } from "../../../../../_services/gen-ai-integrator.service";
import { ActivatedRoute } from "@angular/router";
import { catchError, debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { defaultPrompt, systemPrompt } from "../../../../contestation-ai/contestation-ai-form/default-prompt";

@Component({
	selector: 'checklist-automatic-items-contestation-ai',
	templateUrl: './checklist-automatic-items-contestation-ai.component.html',
	styleUrls: ['./checklist-automatic-items-contestation-ai.component.scss']
})
export class ChecklistAutomaticItemsContestationAiComponent implements OnInit {

	@ViewChild('variablesContainer') variablesContainer!: ElementRef;
	public isFixed = false;
	public offsetTop = 90;
	public scrollY = 0;

	public contestationAi: ContestationAi;
	public contestationAiForm: FormGroup;

	public contestationsAi: PageResponse<ContestationAi>;

	public systemVariables: any[] = [];

	public searchUser: BehaviorSubject<string> = new BehaviorSubject('');

	public aiModels: any[] = [
		{ label: 'Maritaca Sabiá 3', value: EvoAIModels.MARITAKA_AI_SABIA_3 },
		{ label: 'Claude Sonnet V3.5', value: EvoAIModels.AWS_CLAUDE_SONNET_V3_5 },
		{ label: 'Claude Haiku V3.5', value: EvoAIModels.AWS_CLAUDE_HAIKU_V3 }
	];

	public users: PageResponse<User>;
	public userFilter: UserFilter = {
		name: '',
		email: '',
		page: 0,
		linesPerPage: 20,
		orderBy: 'name',
		direction: 'ASC',
		active: true
	}

	public loading: boolean = false;
	public isFormReady: boolean = false;

	public contestationAiFilter: ContestationAiFilter;
	public selectedContestationAi: ContestationAi;

	public currentState: 'selector' | 'form' = 'selector';

	constructor(private fb: FormBuilder,
	            private contestationAiService: ContestationAiService,
	            private userService: UserService,
	            private genAiIntegratorService: GenAiIntegratorService,
	            private toastService: ToastService,
	            private activatedRoute: ActivatedRoute,
	            public modal: NgbActiveModal) {
	}

	public get id() {
		return this.contestationAiForm.get('id');
	}

	public get name() {
		return this.contestationAiForm.get('name');
	}

	public get description() {
		return this.contestationAiForm.get('description');
	}

	public get prompt() {
		return this.contestationAiForm.get('prompt');
	}

	public get aiModel() {
		return this.contestationAiForm.get('aiModel');
	}

	public get responseTag() {
		return this.contestationAiForm.get('responseTag');
	}

	public get detailsTag() {
		return this.contestationAiForm.get('detailsTag');
	}

	public get contestationDefaultUser() {
		return this.contestationAiForm.get('contestationDefaultUser');
	}

	ngOnInit(): void {
		this.loading = true;

		this.setupUserSearch();
		this.getVariables();
		this.getContestationsAi();

		// Fazendo todas as consultas necessárias para o formulário.
		this.activatedRoute.params.pipe(
			switchMap(params => {
				if (params.id && params.id != 'new') {
					return this.contestationAiService.find(params.id).pipe(
						catchError(error => {
							this.toastService.showDanger('Erro ao buscar contestação IA');
							return of(null);
						}),
						switchMap(contestationAi => {
							this.contestationAi = contestationAi;
							return forkJoin({ // fazendo consultas em paralelo
								/*aiModels: this.genAiIntegratorService.findAllAiModels().pipe(
									catchError(error => {
										this.toastService.showDanger('Erro ao buscar modelos de IA');
										return of(null);
									})
								),*/
								users: this.userService.filter(this.userFilter).pipe(
									catchError(error => {
										this.toastService.showDanger('Erro ao buscar usuários');
										return of(null);
									})
								)
							});
						})
					);
				} else {
					return forkJoin({
						// aiModels: this.genAiIntegratorService.findAllAiModels(),
						users: this.userService.filter(this.userFilter)
					});
				}
			})
		).subscribe(({users}) => {
			// this.aiModels = this.moveItemToFront(aiModels, 'Maritaca', 'name');
			this.users = users;
			this.completeForm();
			this.loading = false;
			this.isFormReady = true;
		});
	}

	private getContestationsAi(search: string = ''): void {
		this.contestationAiFilter = {
			page: this.contestationsAi?.pageable.pageNumber ?? 0,
			linesPerPage: 10,
			orderBy: 'createdAt',
			direction: 'DESC',
			textToFilter: search
		}

		this.contestationAiService.filter(this.contestationAiFilter).subscribe(
			data => {
				this.contestationsAi = data;
			}, error => {
				console.error(error);
				this.toastService.showDanger('Erro ao buscar contestações de IA');
			}).add(() => this.loading = false);
	}

	private getVariables(): void {
		this.contestationAiService.getVariables().subscribe(
			data => {
				this.systemVariables = data;
				console.log(data);
			}, error => {
				console.error(error);
			});
	}

	private setupUserSearch(): void {
		this.searchUser.pipe(
			filter((searchTerm: string) => searchTerm && searchTerm.length > 2),
			debounceTime(350),
			tap(() => this.userFilter.page = 0),
			switchMap(searchTerm => {
				this.userFilter.name = searchTerm;
				return this.userService.filter(this.userFilter);
			})
		).subscribe(users => {
			this.users = users;
		});
	}

	public filterUserNextPage(): void {
		this.userFilter.page++;
		this.userService.filter(this.userFilter).subscribe(users => {
			this.users.content = [...this.users.content, ...users.content];
		});
	}

	public save(): void {
		this.loading = true;
		this.postContestationAi();
	}

	private completeForm(): void {
		this.contestationAiForm = this.fb.group({
			id: [null],
			name: ['', [Validators.required]],
			description: [''],
			systemPrompt: [systemPrompt, [Validators.required]],
			prompt: [defaultPrompt, [Validators.required]],
			aiModel: [null, [Validators.required]],
			responseTag: ['', [Validators.required]],
			detailsTag: ['', [Validators.required]],
			contestationDefaultUserId: [null, [Validators.required]]
		});
	}

	private postContestationAi(): void {
		this.contestationAiService.post(this.contestationAiForm.value).subscribe(
			data => {
				this.toastService.showSuccess('Contestação IA salva com sucesso.');
				this.modal.close(data);
			}, error => {
				this.toastService.showDanger('Erro ao salvar contestação IA');
				console.log(error);
			}
		).add(() => this.loading = false)
	}

	public onDragStart(event: DragEvent, variableName: string): void {
		if (event.dataTransfer) {
			// Set the variable name as drag data
			event.dataTransfer.setData('text/plain', variableName);

			// Create a custom drag preview
			const preview = document.createElement('div');
			preview.style.position = 'absolute';
			preview.style.top = '-9999px'; // Move it off-screen
			preview.style.left = '-9999px';
			preview.style.fontSize = '14px';
			preview.style.color = '#fff';
			preview.style.backgroundColor = '#444';
			preview.style.padding = '2px 5px';
			preview.style.borderRadius = '4px';
			preview.style.pointerEvents = 'none';
			preview.textContent = variableName;
			document.body.appendChild(preview);

			// Set the drag image
			event.dataTransfer.setDragImage(preview, 0, 0);

			// Remove the preview after the drag starts
			setTimeout(() => document.body.removeChild(preview), 0);
		}
	}

	public preventInvalidCharacters(event: KeyboardEvent, currentValue: string): void {
		const regex = /^[a-zA-Z0-9]+$/;
		const specialKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

		if (!regex.test(event.key) && !specialKeys.includes(event.key)) {
			event.preventDefault();
			return;
		}

		if (currentValue.length == 0 && /\d/.test(event.key)) {
			event.preventDefault();
		}
	}

	public selectContestationAi(): void {
		this.modal.close(this.selectedContestationAi);
	}
}
