<div class="footer d-flex justify-content-between align-items-center">
    <button [disabled]="isLoading" (click)="onPrev.emit()" class="btn btn-link"><span class="text-primary" style="text-decoration: none">
		{{ prevButtonLabel }}
	</span></button>
    <ng-content select="[progress]"></ng-content>
    <button class="btn btn btn-primary d-flex align-items-center" (click)="onNext.emit()" [disabled]="isFormInvalid || isLoading">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
        <span>{{nextButtonLabel}}</span>
    </button>
</div>
