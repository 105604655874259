<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Adicionar item</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding-top: 0px;" [formGroup]="typeForm">
	<h6 class="m-0">{{ rule.name }}</h6>
	<p class="mb-2">Deseja adicionar esse item como Fixo ou Variável?</p>

	<div class="d-flex">
		<app-checkable-card title="Fixo" formControlName="fixed" iconClass="fa-bullseye" description="Este item irá possuir um peso fixo de acordo com a regra marcada."></app-checkable-card>
		<div class="mx-2"></div>
		<app-checkable-card title="Variável" formControlName="variable" iconClass="fa-sliders-h" description="Este item irá assumir um peso variável com a pergunta selecionada."></app-checkable-card>
	</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(typeSelected)" [disabled]="!isValidForm">Confirmar</button>
</div>
