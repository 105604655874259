import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
	Interaction,
	InteractionFilter,
	InteractionPrint,
	InteractionStatus,
	PageResponse,
	ReportByFilter
} from '@app/models';
import { BaseServiceClass } from './baseService.service';
import * as _ from 'lodash';
import { Observable } from "rxjs";

const API_URL = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class InteractionService extends BaseServiceClass<Interaction> {

	public interactionPrint: InteractionPrint;

	constructor(protected httpService: HttpClient) {
		super(httpService, '/interactions');
	}

	patch(interaction: Interaction) {
		return this.httpService.patch<any>(`${this.entity_url}/${interaction.id}`, interaction);
	}

	contestationPatch(interaction: Interaction) {
		return this.httpService.patch<any>(`${this.entity_url}/contestations/${interaction.id}`, interaction);
	}

	put(interaction: Interaction) {
		return this.httpService.put<any>(`${this.entity_url}/${interaction.id}`, interaction);
	}

	evaluation(interaction: Interaction) {
		return this.httpService.patch<any>(`${this.entity_url}/evaluations/${interaction.id}`, interaction);
	}

	getInteractionReport(reportByFilter: ReportByFilter) {
		// let options = { params: <any> _.omitBy(reportByFilter, _.isNil)};
		return this.httpService.post<any>(`${this.entity_url}/report`, reportByFilter);
	}

	interactionReportDownload(reportByFilter: ReportByFilter, type: string) {
		let options = {responseType: 'blob' as 'json'};
		return this.httpService.post<any>(`${this.entity_url}/report/download/${type}`, reportByFilter, options);
	}

	interactionReportDownloadExcel(reportByFilter: ReportByFilter) {
		let options = {responseType: 'blob' as 'json'};
		return this.httpService.post<any>(`${this.entity_url}/report/download/XLSX`, reportByFilter, options);
	}

	interactionReportDownloadCSV(reportByFilter: ReportByFilter) {
		let options = {responseType: 'blob' as 'json'};
		return this.httpService.post<any>(`${this.entity_url}/report/download/CSV`, reportByFilter, options);
	}

	getAllStatus() {
		return this.httpService.get<InteractionStatus[]>(`${this.entity_url}/status`);
	}

	findAudio(interactionId: number) {
		return this.httpService.get<any>(`${this.entity_url}/${interactionId}/audio`);
	}

	findAllStatus() {
		return this.httpService.get<InteractionStatus[]>(`${this.entity_url}/status`);
	}

	countReleasePendingInteractions(interactionFilter: InteractionFilter): Observable<any> {
		return this.httpService.post(`${this.entity_url}/pending/release/count`, interactionFilter)
	}

	releasePendingInteractions(interactionFilter: InteractionFilter) {
		return this.httpService.post<any>(`${this.entity_url}/pending/release`, interactionFilter);
	}

	monitorWeekly() {
		return this.httpService.get<any>(`${this.entity_url}/monitor/weekly`);
	}

	public filter(obj: any) {
		let options = {params: <any>_.omitBy(obj, _.isNil)};
		return this.httpService.get<PageResponse<any>>(`${this.entity_url}/filter`, options);
	}

	public downloadFile(id) {
		let options = {responseType: 'blob' as 'json'};
		return this.httpService.get<any>(`${API_URL}/buckets/fileInfos/${id}`, options)
	}
}
