export let defaultPrompt = "<informacoes_relevantes>\n" +
	"\n" +
	"Transcrição da interação: $transcription\n" +
	"\n" +
	"Metadados: $interactionMetadata\n" +
	"\n" +
	"Item contestado: $itemName\n" +
	"\n" +
	"Descrição do item: $itemDescription\n" +
	"\n" +
	"Resposta do sistema: $answer\n" +
	"</informacoes_relevantes>\n" +
	"\n" +
	"Analise a seguinte contestação do usuário:\n" +
	"\n" +
	"<contestacao_usuario>\n" +
	"$contestationText\n" +
	"</contestacao_usuario>\n" +
	"\n" +
	"Com base nas <informacoes_relevantes> e na <contestacao_usuario>, responda:\n" +
	"\n" +
	"Liste as palavras-chave relevantes identificadas.\n" +
	"\n" +
	"Avalie a estrutura e sentido lógico da contestação.\n" +
	"\n" +
	"Indique se há evidências na transcrição que suportem ou refutem a contestação.\n" +
	"\n" +
	"Descreva passo-a-passo sua linha de raciocínio que explique sua decisão\n" +
	"\n" +
	"Forneça uma justificativa breve para a decisão.\n" +
	"\n" +
	"Determine se a contestação deve ser ACEITA ou REJEITADA. Caso seja ACEITA, responda com a palavra \"PROCEDENTE\". Caso seja REJEITADA, responda com a palavra \"IMPROCEDENTE\".\n" +
	"\n" +
	"Forneça sua resposta dentro das tags:\n" +
	"<palavras_chave>\n" +
	"<estrutura_contestacao>\n" +
	"<evidencias>\n" +
	"<passo-a-passo>\n" +
	"<justificativa>\n" +
	"<decisao>\n" +
	"\n" +
	"Sua análise deve ser objetiva e baseada em evidências concretas.\n" +
	"Sua resposta deve estar dentro das tags, sem comentários ou explicações adicionais.";

export let systemPrompt: string = "Você é um analista especializado em avaliar contestações.\n" +
	"Sua tarefa é determine se a contestação deve ser ACEITA ou REJEITADA com base nos fatos fornecido.\n" +
	"Lembre-se de que a contestação é feita pelo usuário e suas afirmações podem ser questionáveis.\n" +
	"Sua avaliação deve ser objetiva e baseada nas evidências apresentadas na <contestacao_usuario>.";
